<template>
  <div class="login-container">
    <div class="login-left">
      <img src="@/assets/login-bg-img.png" />
    </div>
    <div class="login-right">
      <div class="login-logo">
        <img src="../../assets/logo-blue.png" alt="" />
      </div>
      <div class="login_main">
        <div class="login-title">建筑综合能源与环境管理平台</div>
        <a-form :model="ruleForm" label-width="0">
          <a-form-item name="account">
            <a-input
              size="large"
              v-model:value="ruleForm.account"
              placeholder="用户名"
              @keyup.enter="toLogin"
            >
              <template #prefix>
                <UserOutlined class="site-form-item-icon" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item name="password">
            <a-input-password
              size="large"
              v-model:value="ruleForm.password"
              placeholder="密码"
              @keyup.enter="toLogin"
              :rules="[{ required: true, message: '请输入密码!' }]"
            >
              <template #prefix>
                <LockOutlined class="site-form-item-icon" />
              </template>
            </a-input-password>
          </a-form-item>
          <a-form-item noStyle>
            <a-button
              style="margin-bottom: 12px"
              size="large"
              type="primary"
              block
              :disabled="!active"
              :loading="loading"
              @click="toLogin"
              >登 录</a-button
            >
          </a-form-item>
          <a-form-item>
            <div class="login-handle">
              <a-checkbox v-model:checked="isLocal">30天免登录</a-checkbox>
              <a-button type="link" @click="forgotPassword">忘记密码</a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <div class="login-record">
        Copyright <copyright-circle-outlined /> 2022 朗绿科技
        <a-button
          type="link"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >沪ICP备16037958号-5</a-button
        >
      </div>
    </div>
  </div>
  <ForgotPassword
    v-if="modalVisible"
    v-model:visible="modalVisible"
    @success="editSuccess"
  ></ForgotPassword>
</template>

<script setup>
import { ref, reactive, computed } from "vue";
import { useUserStore } from "@/store/modules/user";
import md5 from "crypto-js/md5";
import { apiLogin } from "@/api/common";
import ForgotPassword from "./components/ForgotPassword.vue";

const userStore = useUserStore();

const loading = ref(false);
const ruleForm = reactive({
  account: "",
  password: "",
});
const active = computed(() => {
  return !!(ruleForm.account && ruleForm.password);
});

const isLocal = ref(false);

const modalVisible = ref(false);
const forgotPassword = () => {
  modalVisible.value = true;
};
const editSuccess = () => {};

const toLogin = async () => {
  let result = await apiLogin({
    mobileOrEmail: ruleForm.account,
    password: md5(ruleForm.password).toString(),
  });
  userStore.login({ ...result.result, isLocal: isLocal.value });
};
</script>

<style lang="less" scoped>
.login-container {
  display: flex;
  height: 100%;
}
.site-form-item-icon {
  color: rgb(44, 115, 235);
}
.a-form-item {
  margin-bottom: 24px;
}
.login-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  background: url(../../assets/login-bg-right.png) no-repeat fixed center /
    cover;
  img {
    width: 700px;
  }
}
.login-right {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .login-logo {
    position: absolute;
    left: 40px;
    top: 40px;
    width: 140px;
    img {
      width: 100%;
    }
  }
  .login-record {
    position: absolute;
    bottom: 25px;
    color: rgba(0, 0, 0, 0.45);
  }
  .login_main {
    width: 368px;
    .login-title {
      margin-bottom: 50px;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      color: #222532;
    }
  }
  .login-handle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .ant-btn {
      padding: 0;
    }
  }
}
</style>
