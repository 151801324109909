<template>
  <BasicModal
    title="忘记密码"
    width="370px"
    closable
    :footer="null"
    :visible="visible"
    @cancel="cancel"
  >
    <div v-if="step === 1">
      <div class="forgot-password-title">请输入忘记密码的账号:</div>
      <a-input
        v-model:value="account"
        placeholder="请输入账号（手机号/邮箱）"
      />
      <a-button
        type="primary"
        block
        class="forgot-password-button"
        @click="next"
        >下一步</a-button
      >
    </div>
    <div v-else-if="step === 2">
      <div class="forgot-password-title">
        已向您账号的邮箱<span>{{ email }}</span
        >发送重置密码的口令。请在下方输入框中填入收到的口令。
      </div>
      <a-input v-model:value="code" placeholder="请输入收到的口令" />
      <a-button
        type="primary"
        block
        class="forgot-password-button"
        @click="confirm"
        >提交</a-button
      >
    </div>
    <div v-else-if="step === 3">
      <div class="forgot-password-title">
        重置密码成功！新密码已经发送到您的邮箱<span>{{ email }}</span
        >，请前往查收！
      </div>
      <a-button
        type="primary"
        block
        class="forgot-password-button"
        @click="cancel"
        >确定</a-button
      >
    </div>
  </BasicModal>
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";
import { message } from "ant-design-vue";
import { apiForgotCode, apiForgot } from "@/api/common";

defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:visible"]);

const step = ref(1);

const email = ref("");
const account = ref("");
const nextLoading = ref(false);
const next = async () => {
  if (account.value) {
    nextLoading.value = true;
    try {
      const res = await apiForgotCode(account.value);
      nextLoading.value = false;
      email.value = res.result;
      step.value = 2;
    } catch (error) {
      nextLoading.value = false;
      console.log(error);
    }
  } else {
    message.warning("请输入账号");
  }
};

const code = ref("");
const confirmLoading = ref(false);
const confirm = async () => {
  if (code.value) {
    confirmLoading.value = true;
    const params = {
      account: account.value,
      code: code.value,
    };
    try {
      await apiForgot(params);
      confirmLoading.value = false;
      step.value = 3;
    } catch (error) {
      confirmLoading.value = false;
      console.log(error);
    }
  } else {
    message.warning("请输入口令");
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.forgot-password-title {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 8px;
  span {
    color: #0256ff;
  }
}
.forgot-password-button {
  margin: 20px 0 44px;
}
</style>
